import './Slideword.scss';

export const Slideword = () =>{

  return(
    <div className='slideword'>
      <div className='word-wrap'>
        <p>I aspire to be a developer who<span>&nbsp; thinks deeply and never stops learning.</span></p>
      </div>
      <div className='word-wrap'>
      <p>I aspire to be a developer who<span>&nbsp; thinks deeply and never stops learning.</span></p>
      </div>

    </div>
  )
};